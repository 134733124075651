import React from 'react'
import { SeparatorProps } from './separator.props'
import { useStyles } from './separator.styles'

export const Separator = (props: SeparatorProps) => {
  const { className } = props
  const styles = useStyles(props)

  return (
    <div
      className={className}
      data-testid='separator'
    >
      <hr className={styles.separator} />
    </div>
  )
}
