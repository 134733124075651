import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'

interface SeparatorStyles {
  separator: Styles
}

interface SeparatorStylesProps {
  borderWidth: number
  borderColor: string
}

export const useStyles = createUseStyles<SeparatorStyles, SeparatorStylesProps>((theme) => {
  return {
    separator: ({ borderWidth, borderColor }) => ({
      border: `${theme.calcUnit(borderWidth)} solid ${borderColor}`,
      margin: theme.calcUnit(0),
    }),
  }
})
