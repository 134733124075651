import { createUseStyles, Styles } from '@pff-consumer/web-ui/styles'

interface PffInsightsStyles {
  messagesContainer: Styles
  separator: Styles
  subHeader: Styles
  insightsContainer: Styles
  icon: Styles
  insightsPoints: Styles
  insightDescription: Styles
}

export const useStyles = createUseStyles<PffInsightsStyles>((theme) => {
  return {
    messagesContainer: {
      marginBottom: theme.calcUnit(theme.spacing.s2),
      marginTop: theme.calcUnit(theme.spacing.s3),
      width: '100%',
    },
    separator: {
      marginTop: theme.calcUnit(theme.spacing.s3),
      marginBottom: theme.calcUnit(theme.spacing.s3),
    },
    subHeader: {
      marginBottom: theme.calcUnit(theme.spacing.s3),
    },
    insightsContainer: {
      marginBottom: theme.calcUnit(theme.spacing.s3),
      position: 'relative',
    },
    icon: {
      position: 'absolute',
      top: theme.calcUnit(4),
    },
    insightsPoints: {
      marginLeft: theme.calcUnit(theme.spacing.s8),
    },
    insightDescription: {
      marginLeft: theme.calcUnit(theme.spacing.s2),
    },
  }
})
